<template>
	<div ref="termly">
		<div
			name="termly-embed"
			data-id="954ffc3e-6e85-4969-b8d7-b3be96c1ec45"
			data-type="iframe"
		></div>
		<div class="opt">
			If you would like to opt out of Discord's message intents, please click
			<a @click="optOut">here</a>.
		</div>
	</div>
</template>

<script>
export default {
	name: 'PrivacyPolicyPage',
	methods: {
		async optOut() {
			const opt = {
				text: "Are you sure you want to opt out of Discord's message intents? THIS IS NOT REVERSABLE AND YOU WILL LOSE SOME BOT FUNCTIONALITY. Please understand what you are doing before opting out.",
				buttons: [
					{
						text: 'I Agree',
						color: 'primary',
						returns: true,
					},
					{
						text: 'Cancel',
						color: 'error',
						returns: false,
					},
				],
			};

			const result = await this.$modal(opt).showWarn();
			if (!result) return;
			try {
				await this.$store.dispatch('optOutMessageIntent');
				const opt = {
					text: 'Successfully opted out of message intents. Please give it some time to take affect. Some OwO Bot commands will not work for you after your message intents are disabled.',
				};
				this.$modal(opt).showInfo();
			} catch (err) {
				console.error(err.response.status);
				const opt = {
					text: 'Failed to opt out.. Please contact Scuttler#0001',
					imgUrl: 'owo-cry.png',
				};
				if (err.response.status === 401) {
					opt.text = 'Please log in before opting out.';
				}
				this.$modal(opt).showError();
			}
		},
	},
	mounted() {
		const script = document.createElement('script');
		script.src = 'https://app.termly.io/embed-policy.min.js';
		script.id = 'termly-jssdk';
		this.$refs.termly.appendChild(script);
	},
};
</script>

<style scoped>
.opt {
	margin-left: 30px;
	margin-top: -80px;
	margin-bottom: 30px;
	z-index: 1;
	position: absolute;
}
</style>
